<template>
  <v-app>
    <v-navigation-drawer   app permanent dark   style="width:80px;background: linear-gradient(rgba(14, 45, 76, 1), rgba(64, 157, 149, 1))" onmouseover="this.style.width = '250px'" onmouseout="this.style.width = '80px'">
      <!-- overlay-opacity="50"  -->
      <div class="d-flex flex-column font-weight-bold" style="height:100%">
        <div >
          <v-list shaped>
            <v-list-item class="px-2">
              <v-img src="@/assets/img/white-favicon.png" max-width="40" max-height="40" class="pt-10 mb-5"></v-img>
              <v-list-item-avatar>
              </v-list-item-avatar>
            </v-list-item>

            <v-list-item link to="/admin" >
              <v-list-item-icon>
                <v-icon :color="iconColor"  size="33" >mdi-home</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
              <v-list-item-title class="text-uppercase orange-lighten-text">Home</v-list-item-title>
              </v-list-item-content>

            </v-list-item>
          <v-divider></v-divider>

            <v-list-item v-for="item in menuItems" :key="item.text" link :to="item.to" >
              <v-list-item-icon>
                <v-icon :color="iconColor" size="33" >{{item.icon}}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="text-uppercase">{{item.text}}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

          </v-list>
        </div>
        <v-spacer class="mr-16"></v-spacer>
        <div>
          <v-divider></v-divider>
          <v-list shaped nav>
            <v-list-item link to="/admin/profile/changepassword">
              <v-list-item-icon>
                <v-icon :color="iconColordark" size="33">mdi-account</v-icon>
              </v-list-item-icon>
              <v-list-item-title>PROFILE</v-list-item-title>
            </v-list-item>            
            <v-list-item @click="logout()">
              <v-list-item-icon>
                <v-icon :color="iconColordark" size="33">mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-title>LOGOUT</v-list-item-title>
            </v-list-item>

          </v-list>
        </div>
      </div>

    </v-navigation-drawer>
    <v-main class="pl-16 ml-4">
          <v-slide-x-transition mode="out-in">
            <router-view></router-view>
          </v-slide-x-transition>
    </v-main>

  </v-app>
</template>

<script>

export default {
  name: 'Home',

  data: () => ({
    iconColor:'#75ca71',
    iconColordark:'rgba(14, 45, 76, 0.8)',
    menuItems: [
        {
          text: 'Databases',
          to: '/admin/databases',
          icon: 'mdi-database'
        },
        {
          text: 'Accounts',
          to: '/admin/users',
          icon: 'mdi-account-multiple'
        },
        {
          text: 'Settings',
          to:'/admin/settings/columngroups',
          icon: 'mdi-cogs'
        }
      ],
       profileMenuItems: [
        {
          text: 'Profile',
          to: '/admin/profile/changepassword',
          icon: 'mdi-account',
        },
        {
          text: 'Logout',
          to: null,
          icon: 'mdi-logout',
        }
      ]

  }),
  methods: {
        logout () {
      this.$store.commit('logout')
      this.$router.push('/login')
    },
  },
  //   mounted: function() {
  //   let elHtml = document.getElementsByTagName('html')[0]
  //   elHtml.style.overflowY = 'hidden'
  // },
  // destroyed: function() {
  //   let elHtml = document.getElementsByTagName('html')[0]
  //   elHtml.style.overflowY = null
  // }

};
</script>
