<template>
<div class="mt-8 mx-8" >
  <v-data-table
    :headers="headers"
    :items="users"
    sort-by="name"
    class="elevation-1"
  >
      <template v-slot:item.is_staff="{ item }">
        <v-simple-checkbox
          v-model="item.is_staff"
          disabled
        ></v-simple-checkbox>
      </template>
      <template v-slot:item.is_active="{ item }">
        <v-simple-checkbox
          v-model="item.is_active"
          disabled
        ></v-simple-checkbox>
      </template>
    <template v-slot:top>
      <v-toolbar flat >
        <v-toolbar-title>ACCOUNTS </v-toolbar-title>
        <v-divider class="mx-4" inset vertical ></v-divider>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="600px" >
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on"  >
              <v-icon class="pr-2">mdi-account-plus</v-icon>New Account
            </v-btn>
          </template>
           <v-form ref="form" v-model="valid" lazy-validation >

          <v-card>
            <v-card-title class="accent">
              <span  class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col  >
                    <v-text-field :rules="[rules.required]" validate-on-blur v-model="selectedUser.first_name" label="Firstname" ></v-text-field>
                  </v-col>
                  <v-col  >
                    <v-text-field :rules="[rules.required]" validate-on-blur v-model="selectedUser.last_name" label="Lastname" ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col  >
                    <v-text-field  :rules="[rules.required, rules.email]" validate-on-blur v-model="selectedUser.email" label="Email" ></v-text-field>
                  </v-col>
                  <v-col  >
                    <v-text-field :rules="[rules.required]" validate-on-blur v-model="selectedUser.username" label="Username" ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col  >
                    <v-text-field :append-icon="showPassword1 ? 'mdi-eye' : 'mdi-eye-off'"
                                  :rules="[rules.required, rules.min]"
                                  validate-on-blur
                                  :type="showPassword1 ? 'text' : 'password'" 
                                  v-model="selectedUser.password" 
                                  label="Password" 
                                  class="input-group--focused"
                                  @click:append="showPassword1 = !showPassword1"></v-text-field>
                  </v-col>
                  <v-col  >
                    <v-text-field :append-icon="showPassword2 ? 'mdi-eye' : 'mdi-eye-off'"
                                  :rules="[rules.required, rules.min, (selectedUser.password === selectedUser.password2) || 'Password must match']"
                                  validate-on-blur
                                  :type="showPassword2 ? 'text' : 'password'" 
                                  v-model="selectedUser.password2" 
                                  label="Password" 
                                  class="input-group--focused"
                                  @click:append="showPassword2 = !showPassword2"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col  >
                    <v-switch  v-model="selectedUser.is_staff" label="Admin" ></v-switch>
                  </v-col>
                  <v-col  >
                    <v-switch  v-model="selectedUser.is_active" label="Active" ></v-switch>
                  </v-col>                  
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close" >
                Cancel
              </v-btn>
              <v-btn :disabled="!valid" color="blue darken-1" text @click="save" >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
           </v-form>

        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="accent">
              <span  class="text-h5">Delete Account </span>
            </v-card-title>            
            <v-card-text class="body-1 mt-6 ">Are you sure you want to delete <b>{{selectedUser.username}}</b>?</v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary darken-1" text @click="closeDelete">Cancel</v-btn>
              <v-btn color="red darken-1" text @click="deleteItemConfirm">YES</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-tooltip top color="primary">
        <template v-slot:activator="{ on }">
            <v-btn
              icon
              disabled
              color="primary"
              v-on="on" 
            >
              <v-icon class="mr-2">mdi-key-star</v-icon>
            </v-btn>          
        </template>
        <span> Reset <strong>{{item.username}}</strong>'s Password</span>
    </v-tooltip>

    <router-link :to="{name:'userInfo', params:{id: item.id}}" class="text-decoration-none" >
      <v-tooltip top color="primary">
        <template v-slot:activator="{ on }">
            <v-btn
              icon
              large
              color="primary"
              v-on="on" 
            >
              <v-icon class="mr-2">mdi-account-details</v-icon>
            </v-btn>          
        </template>
        <span> View <strong>{{item.username}}</strong> Account Detail</span>
    </v-tooltip>
    </router-link>
      <v-tooltip top color="orange">
        <template v-slot:activator="{ on }">
            <v-btn
              icon
              large
              color="orange"
              @click="deleteItem(item)" 
              v-on="on" 
            >
              <v-icon class="mr-2">mdi-delete-forever</v-icon>
            </v-btn>          

        </template>
        <span>Delete <strong>{{item.username}}</strong> </span>
      </v-tooltip>



    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize" >
        Reset
      </v-btn>
    </template>
  </v-data-table>
</div>
</template>

<script>
  import { getUsers, addUser, deleteUser } from '@/api/users.js'
  export default {
    data: () => ({
      valid: true,
      dialog: false,
      showPassword1: false,
      showPassword2: false,
      dialogDelete: false,
      headers: [
        { text: 'FIRSTNAME', align: 'start', value: 'first_name' ,class:"accent h2"},
        { text: 'LASTNAME', value: 'last_name' ,class:"accent"},
        { text: 'USERNAME', value: 'username',class:"accent" },
        { text: 'EMAIL', value: 'email' ,class:"accent"},
        { text: 'ACTIVE', value: 'is_active' ,class:"accent"},
        { text: 'ADMIN', value: 'is_staff' ,class:"accent"},
        { text: 'ACTIONS', divider:true, value: 'actions', sortable: false ,class:"accent"},
      ],
      users: [],
      editedIndex: -1,
      selectedUser: {
        id: 0,
        username: '',
        email: '',
        first_name:'',
        last_name:'',
        is_staff: false,
        is_active: true,
        password: '',
        password2: ''
      },
      defaultItem: {
        id: 0,
        username: '',
        email: '',
        first_name:'',
        last_name:'',
        is_active: true,
        is_staff: false,
        password: '',
        password2: ''
      },
      rules: {
          required: value => !!value || 'Required.',
          counter: value => value.length <= 20 || 'Max 20 characters',
          min: v => v.length >= 8 || 'Min 8 characters',
          // passwordMatch: () => selectedUser.password != selectedUser.password2 || 'Passwords doesn\'t match',
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid e-mail.'
          },
      }      
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'New User' : 'Edit User'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.initialize()
    },

    methods: {
      loadUsers(){
        const _this = this
        getUsers(function(res){
          _this.users = res
        })        
      },
      initialize () {
        this.loadUsers()
      },

      deleteItem (item) {
        this.selectedUser = item
        this.dialogDelete = true
      },
      deleteItemConfirm () {
        const _this = this
        deleteUser(this.selectedUser.id, function(res){
          console.log('HERE')
          console.log(res)
          _this.loadUsers()
        }, 'User ' + _this.selectedUser.username + " removed successfully" )
        this.closeDelete()
      },
      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.selectedUser = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.selectedUser = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        var isValidated = this.$refs.form.validate()
        if (isValidated){
          const _this = this
          addUser(this.selectedUser, function(){
            _this.loadUsers()
            _this.close()
          }, function (err){
            if (err.status == 400){
              var msg = 'Invalid data entered! '
              if(err.data.email) {
                msg += 'Another user with ' + _this.selectedUser.email + ' already exist!'
              } else {
                for ( const k of Object.keys(err.data)){
                  msg += k + ':' + err.data[k][0]
                }
              }
              _this.$root.notify.show({message: msg, type:'error'})
            } else {
              _this.$root.notify.show({message: 'Internal Error!', type:'error'})
            }
          }, 'Account ' + _this.selectedUser.username +  ' added successfully'
          )
        }
      },
    },
  }
</script>