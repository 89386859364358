<template>
    <CFrame>
        <!-- <v-switch
      v-model="$vuetify.theme.dark"
      :label="`Switch 1: ${$vuetify.theme.dark}`"
    ></v-switch> -->
        <div style="width:200px;height:500px"></div>
    </CFrame>
</template>
<script>
import CFrame from '@/components/Vuetify/CFrame.vue'
export default {
    name: 'Home',
    components: {CFrame}
}
</script>

